'use client'

import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'

const ReactQueryDevtools = dynamic(
  () => import('@tanstack/react-query-devtools/build/modern/production.js').then((mod) => mod.ReactQueryDevtools),
  { loading: () => null },
)

export function ReactQueryDevtoolsForProduction({ defaultShowDevtools = false }: { defaultShowDevtools?: boolean }) {
  const [showDevtools, setShowDevtools] = useState(defaultShowDevtools)

  useEffect(() => {
    window.toggleReactQueryDevtools = () => setShowDevtools((old) => !old)
  }, [])

  return showDevtools && <ReactQueryDevtools initialIsOpen={false} />
}
