'use client'
import '@rainbow-me/rainbowkit/styles.css'

import { TooltipProvider } from '@radix-ui/react-tooltip'
import { RainbowKitProvider, getDefaultConfig } from '@rainbow-me/rainbowkit'
import { type GetSiweMessageOptions, RainbowKitSiweNextAuthProvider } from '@rainbow-me/rainbowkit-siwe-next-auth'
import { SessionProvider } from 'next-auth/react'
import { http, WagmiProvider } from 'wagmi'
import { mainnet } from 'wagmi/chains'
import ReactQueryClientProvider from './ReactQueryClientProvider'

const getSiweMessageOptions: GetSiweMessageOptions = () => ({
  statement: 'Sign in with Ethereum to Sensay.',
})

const config = getDefaultConfig({
  appName: 'Sensay - AI Dementia Care | Preserving Cherished Memories & Empowering Care',
  projectId: 'd243c2b24beef837dc943e2073dbf76e',
  chains: [mainnet],
  transports: {
    [mainnet.id]: http(),
  },
  ssr: true,
})

const AuthContext = ({ children }: { children: React.ReactNode }) => {
  return (
    <WagmiProvider config={config}>
      <SessionProvider>
        <ReactQueryClientProvider>
          <RainbowKitSiweNextAuthProvider getSiweMessageOptions={getSiweMessageOptions}>
            <RainbowKitProvider>
              <TooltipProvider>{children}</TooltipProvider>
            </RainbowKitProvider>
          </RainbowKitSiweNextAuthProvider>
        </ReactQueryClientProvider>
      </SessionProvider>
    </WagmiProvider>
  )
}

export default AuthContext
